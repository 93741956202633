.form__input-container {
  min-width: 100%;
}

.form__input {
  min-width: 100%;
  height: 2rem;
  border-radius: 1px;
  border: 1px solid black;
}

.form__label {
  font-size: 1.75rem;
  color: var(--text-primary);
}
