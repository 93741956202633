.message-container,
.message-container-clickable {
  justify-content: center;
  align-content: center;
  text-align: 'center';
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: -15px;
}

.message-container-clickable {
  cursor: grab;
}
