@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
/* this css is for pages like Upload, Drop_off, and Home pages */
.pages {
  margin: auto;
  background-color: white;
  width: 27em;
  height: 30em;
  padding-top: 0.5em;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 1em;
  font-family: serif;
  font-family: 'Lato', sans-serif;
}

.center_text {
  text-align: center;
  line-height: 0em;
}

.bold_center_text {
  text-align: center;
  font-weight: bolder;
  font-size: 2em;
  line-height: 0em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-family: serif;
  font-family: 'Lato', sans-serif;
}

.upload_btn {
  font-size: medium;
  padding: 13px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #bfbfbf;
  color: #45445a;
  margin-top: 1em;
  font-family: serif;
}

.submit-btn {
  margin-top: 1rem;
  text-align: center;
  font-size: large;
  font-family: 'Lato', sans-serif;
}

.contact {
  margin: auto;
  /* position: fixed; */
  font-family: serif;
  word-wrap: nowrap;
  font-size: 25px;
  color: #03143e;
  font-family: 'Lato', sans-serif;
}

.contactDrop {
  position: absolute;
  justify-content: center;
  top: 93%;
  left: 8%;
  font-family: serif;
  font-size: 25px;
  color: #03143e;
  font-family: 'Lato', sans-serif;
}

.error {
  margin: 10px 1px;
  padding: 20px;
  vertical-align: middle;
  color: #d8000c;
  font-size: 0.9em;
  background-color: #ffd2d2;
  font-family: FontAwesome;
}

.dropOffLoader {
  text-align: center;
  margin-top: 3.5em;
}

.dropOffInput {
  /* font-family: 'Lato', sans-serif;
  font-weight: bolder ; */
  line-height: 50px;
  font-size: 36px;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 8px;
}
.character--selected {
  border: 1px solid white;
}

.dropOffInputDiv {
  display: flex;
  justify-content: center;
}
