.navbar {
  background: #ffff;
  height: 100px;
  width: 100%;
  z-index: 999;
  border-bottom-style: solid;
  border-color: rgb(0, 48, 87);
  border-width: 2px;
  display: flex;
}

.navbar-logo {
  padding-left: 1rem;
  display: flex;
  position: absolute;
  padding-right: 20px;
}

/* .fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
} */

.nav-menu {
  display: flex;
  align-items: center;
  margin: auto;
  float: left;
  flex: 0 0 100%;
  white-space: nowrap;
  padding-left: 18rem;
  overflow: hidden;
}

.nav-item {
  display: flex;
  float: left;
}

.nav-links {
  color: #000;
  align-items: center;
  text-decoration: none;
  padding: 1.4rem;
  margin-right: 2px;
  margin-left: 2px;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.nav-links:hover {
  transition: all 0.2s ease-in-out;
  background-color: #003057;
  color: #fff;
  border-radius: 4px;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

/* this is for the mobile version with a dropdown menu */

@media screen and (max-width: 768px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .nav-menu.active {
    background: #003057;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1rem;
    padding-bottom: 10px;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  /* I don't think the bottom two blocks do anything, but will keep just in case */
  /* .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;

  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;

  } */
}
