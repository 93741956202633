.clientsPopupUsernameTitle {
  font-size: 40px;
  padding-bottom: 15px;
  float: left;
}

.PopupGridItem {
  font-size: 16px;
  margin: 0;
}

body {
  padding: 0;
  background-color: white;
}

.popup [type='email'],
.popup [type='text'],
.popup [type='number'],
.popup [type='university'],
.popup [type='date'] {
  padding: 0.7rem 0.8rem;
  font-size: 0.9rem;
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
}

.popup [type='email'],
.popup [type='text'],
.popup [type='number'],
.popup [type='date'] {
  width: 250px;
  display: block;
}

.formError {
  /* margin: 5px 2px;
  *     margin-top: 0.25rem; */
  padding: 2px;
  color: #d8000c;
  font-size: 0.9em;
  background-color: #ffd2d200;
  font-family: 'Lato', bold;
  float: left;
  font-weight: bold;
}

.formErrorCREdit {
  /* margin: 5px 2px;
  *     margin-top: 0.25rem; */
  padding: 2px;
  color: #d8000c;
  font-size: 0.9em;
  background-color: #ffd2d200;
  font-family: 'Lato', bold;
  font-weight: bold;
}

label {
  font-size: 0.9rem;
  display: flex;
}
.NewUsertext {
  display: block;
}

.profileSubmit {
  margin-bottom: 70px;
  font-size: medium;
  padding: 13px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #03143e;
  color: white;
  font-family: 'Lato', sans-serif;
}

.usernameWarning {
  color: #d8000c;
  font-size: medium;
  font-style: italic;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}
