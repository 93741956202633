@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');

:root {
  /* Colors */
  --text-primary: #003057ff;
  --text-secondary: #5d778cff;
  --text-accent: #008afcff;
  --border-color: #7bb9ecff;

  --locations-background: #eeeeeeff;

  /* Button */
  --button-background: #003057ff;
  --button-text: #ffffffff;
  --button-border: #7bb9ecff;
  --button-text-hover: #d1e4f5;

  /* Text */
  --subheading-size: 1.5rem;
  --font-primary: 'Roboto', sans-serif;

  /* Phone size */
  --phone-width: 985px;
  --map-height: 620px;

  /* Default variables that will be overridden if size is phone size */
  --min-width: 250px;
  --margin-top: 35%;
  --animation-delay: 0.5s;
  --title-size: 25px;

  --card-width: 600px;
  --card-background: #ffffffff;
  --card-border: #7bb9ecff;

  /* Phone size */
  @media all and (max-width: var(--phone-width)) {
    --min-width: 250px;
    --margin-top: 7%;
    --animation-delay: 0s;
    --title-size: 27px;
  }
}

* {
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.page__title {
  font-size: 4rem;
  font-weight: 900;
  border-bottom: 12px solid var(--border-color);
  margin: 30px 0;
  color: var(--text-primary);
}

.flex-break {
  flex-basis: 100%;
}
