input[type='emailProfile'],
input[type='textProfile'],
input[type='numberProfile'] input[type='universityProfile'] {
  padding: 0.7rem 0.8rem;
  font-size: 1rem;
  border-radius: 10px;
  border-style: solid;
  border-color: rgb(204, 204, 204);
}

input[type='emailProfile'],
input[type='textProfile'],
input[type='numberProfile'] {
  width: 280px;
  display: block;
}

.form__container .formError {
  /* margin: 5px 2px;
  margin-top: 0.25rem; */
  padding: 2px;
  color: #d8000c;
  font-size: 0.9em;
  background-color: #ffd2d200;
  font-family: 'Lato', bold;
  float: left;
  font-weight: bold;
}

.form__container .formErrorCREdit {
  padding: 2px;
  color: #d8000c;
  font-size: 0.9em;
  background-color: #ffd2d200;
  font-family: 'Lato', bold;
  font-weight: bold;
}

.form__container label {
  font-size: 1.09rem;
  display: flex;
}

.form__container .ProfilePageTitle {
  font-size: 3.09rem;
  display: flex;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0;
}

.form__container .NewUsertext {
  display: block;
}

.form__container .profileSubmit {
  margin-bottom: 70px;
  font-size: large;
  padding: 13px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #03143e;
  color: white;
  font-family: 'Lato', sans-serif;
}

.form__container .PIwarning {
  font-size: medium;
  font-style: italic;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}
