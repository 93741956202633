.contact__container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10%;
}

.contact__container .contact__form {
  margin-top: 2rem;
}

.contact__container .contact__title {
  font-size: 3rem;
  color: var(--text-primary);
  font-weight: 600;
}

.contact__container .contact_submit-container {
  display: flex;
  justify-content: end;
  margin-top: 1rem;
}

.contact_submit-button {
  background-color: var(--text-accent);
  padding: 0.25rem 0.75rem;
  color: white;
  font-family: var(--font-primary);
  font-weight: 500;
  border: none;
  border-radius: 5px;
}

.contact__container .contact__subcontainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 1rem;
}

.contact__container .contact__subheading {
  font-size: var(--subheading-size);
  color: var(--text-primary);
}
