.height600 {
  position: relative;
  height: 600px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  padding: 16px 32px 24px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.calendarView {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tablesContainer {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
}

/* Table of all users and their assigned orders */
.usersTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.usersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.usersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

.usersTable th:nth-child(odd) {
  border-right: 2px solid #8d8d8d;
}

.usersTable th:nth-child(even) {
  border-right: 0;
}

/* tr */
.usersTable tr:nth-child(even) {
  background-color: rgba(123, 185, 236, 0.3);
}

/* td */
.usersTable td {
  text-align: center;
  color: #003057;
}

.usersTable td:nth-child(odd) {
  border-right: 2px solid #8d8d8d;
}

.usersTable td:nth-child(even) {
  border-right: 0;
}

.usersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
}

.usersTable tr:last-child td:last-child {
  border-bottom-right-radius: 7.5px;
}

.availabilityTablesContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

/* Table of all FREE users */
.availableUsersTable {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.availableUsersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.availableUsersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.availableUsersTable tr:nth-child(even) {
  background-color: #69b58c;
}

/* td */
.availableUsersTable td {
  text-align: center;
  color: #003057;
}

.availableUsersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

/* Table of all UNASSIGNED orders */
.unassignedOrdersTable {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.unassignedOrdersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.unassignedOrdersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.unassignedOrdersTable tr:nth-child(even) {
  background-color: #f6a7a7;
}

/* td */
.unassignedOrdersTable td {
  text-align: center;
  color: #003057;
}

.unassignedOrdersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

/* Table of all ASSIGNED orders */
.assignedOrdersTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.assignedOrdersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.assignedOrdersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.assignedOrdersTable tr:nth-child(even) {
  background-color: rgba(123, 185, 236, 0.3);
}

/* td */
.assignedOrdersTable td {
  text-align: center;
  color: #003057;
}

.assignedOrdersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

/* Table of all UNASSIGNED orders INSIDE Technician's Page */
.unassignedOrdersTableTab {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.unassignedOrdersTableTab table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.unassignedOrdersTableTab th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.unassignedOrdersTableTab tr:nth-child(even) {
  background-color: #f6a7a7;
}

/* td */
.unassignedOrdersTableTab td {
  text-align: center;
  color: #003057;
}

.unassignedOrdersTableTab tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

/* Table of all INPROGRESS orders */
.inprogressOrdersTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.inprogressOrdersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.inprogressOrdersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.inprogressOrdersTable tr:nth-child(even) {
  background-color: #f2d77a;
}

/* td */
.inprogressOrdersTable td {
  text-align: center;
  color: #003057;
}

.inprogressOrdersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

/* Table of all PROCESSED orders */
.processedOrdersTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 12px;
}

.processedOrdersTable table {
  width: 100%;
  border: 2px solid #8d8d8d;
  margin-top: 8px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 10px;
}

/* th */
.processedOrdersTable th {
  text-align: center;
  border-bottom: 2px solid #8d8d8d;
  color: #003057;
}

/* tr */
.processedOrdersTable tr:nth-child(even) {
  background-color: #69b58c;
}

/* td */
.processedOrdersTable td {
  text-align: center;
  color: #003057;
}

.processedOrdersTable tr:last-child td:first-child {
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
}

#selectableName {
  cursor: pointer;
}

#selectableName:hover {
  opacity: 0.4;
}

.calendarContainer {
  width: 75%;
}

/* Training Progress Bar */
.trainingProgress {
  width: 100%;
}

.trainingProgress table {
  width: 100%;
  border: 2px solid black;
  border-collapse: separate;
  border-radius: 10px;
  background-color: gray;
}

/* th */
.trainingProgress th {
  text-align: center;
  color: #003057;
  padding: 0px 25px;
  border-right: 2px solid black;
  color: white;
}

.trainingProgress th:last-child {
  border-right: 0;
}
