/* Home page container */
.containerHome video {
  position: relative;
  z-index: 0;
  padding: 0;
  margin: 0;
}

.containerHome {
  position: relative;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
  height: auto;
  display: flex;
  overflow: hidden;
}

/* Instruction Blocks */
.instruct__container {
  align-items: flex-start !important;
  margin: auto;
  padding: 0 60px;
  max-width: 1100px;
}

.instruct__block {
  display: flex;
  min-width: 200px;
  flex-direction: column;
  gap: 20px;
  margin: 0 20px;
}

.instruct__header {
  text-align: left;
  top: 10%;
  color: var(--text-primary);
  white-space: nowrap;
  font-size: var(--title-size);
}

.instruct__header text {
  color: var(--text-accent);
}

.instruct__text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 17px;
  text-align: left;
  padding: 0;
  color: var(--text-secondary);
  font-weight: lighter;
  min-height: 130px;
}

.instruct__footer {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  color: var(--text-accent);
  margin-bottom: 30px;
}

.instruct__button :first-child {
  display: block;
  width: 100%;
  padding: 2px;

  color: var(--button-text);
  border: var(--text-accent) solid 1px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  background-color: var(--button-background);

  transition: all 0.2s;
}

.instruct__button :first-child:hover {
  color: var(--button-text-hover);
  background-color: var(--button-background);
}

.instruct__button {
  width: 100%;
}

.instruct--spaced {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  gap: 14px;
}

.instruct__note {
  /* Note for Get Results section */
  font-size: 12px;
}

.instruct__animation--fade-in {
  /* Instructions fade in */
  opacity: 1;
  transition: opacity 1s ease-in;
}

.instruct__animation--fade-out {
  /* Instructions fade out */
  opacity: 0;
  transition: opacity 1s ease-out;
}

.instruct__animation-delay--1 {
  /* Set animation delay */
  transition-delay: var(--animation-delay);
}

.instruct__animation-delay--2 {
  /* Set animation delay */
  transition-delay: calc(2 * var(--animation-delay));
}

/* Other misc. styles */
.home__locations {
  min-width: 100%;
  padding: 2% 5%;
  background-color: var(--locations-background);
}

.TextColorSlide {
  background: linear-gradient(
      to left,
      var(--text-primary) 50%,
      var(--text-accent) 50%
    )
    right;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
}

.overlayHome {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0;
  margin: 0;
}

.tinyThumb {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
