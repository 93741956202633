/* Location map styles */
.locations__container {
  min-height: var(--map-height);
  display: flex;
  flex-direction: column;
}

.locations__title {
  color: var(--text-primary);
  font-size: 3.5rem;
  font-weight: 500;
  text-align: left;
}

.locations__container-selectable {
  display: flex;
  flex-direction: row;
  min-height: var(--map-height);
  justify-content: center;
}

.locations__map-container {
  position: relative;
  transition: 0.7s;
  min-height: 80%;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 3rem;
}

.locations__map-container--click-reset {
  min-height: var(--map-height);
  min-width: 100%;
  z-index: 0;
  position: absolute;
}

.locations__map {
  width: 100%;
  height: var(--map-height);
  position: absolute;
  top: 0;
  left: 0;
}

.locations__building {
  cursor: pointer;
  position: absolute;
  right: 50%;
}

.locations__building-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: var(--text-primary);
  font-weight: 500;
}

.locations__list {
  padding: 10px;
  min-width: 20px;
  width: 200px;
  z-index: 6;

  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.locations__list-item {
  color: var(--text-primary);
  font-weight: 400;
  cursor: pointer;
}

/* Building card styles */

.building-card__container {
  position: absolute;
  background-color: var(--card-background);
  border: 10px solid var(--card-border);
  padding: 30px;
  z-index: 10;
  width: var(--card-width);

  /* Fly in animation */
  transition: 0.5s;

  /* Centering in parent */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.building-card--hidden {
  opacity: 0;
  left: -50%;
}

.building-card--shown {
  opacity: 1;
}

.building-card__title {
  color: var(--text-primary);
  font-size: 40px;
  font-weight: 500;
}

.building-card__desc {
  color: var(--text-primary);
  margin: 10px 0;
}

.building-card__img-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
}

.building-card__img-container {
  /* Allow image to respond to flexbox */
  flex: 1;
}

.building-card__img-container img {
  /* Make images fit container */
  width: 100%;
  height: 100%;
  overflow: hidden;
}
