.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 100;
  display: inline-block !important;
}

.dropdown-menu li {
  background: #ffffff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  transition: all 0.2s ease-in-out;
  background-color: #003057;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000;
  padding: 16px;
}

.dropdown-link:hover {
  transition: all 0.2s ease-in-out;
  background-color: #003057;
  color: #fff;
  border-radius: 4px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
