em {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  list-style-type: none;
  font-weight: lighter;
}

.faq button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /*input has OS specific font-family*/
  color: rgb(43, 121, 255);
  text-decoration: underline;
  cursor: pointer;
}

a {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  list-style-type: none;
}

.faq__container {
  position: relative;
  margin: 0 auto;
  max-width: 900px;
  padding: 10px 20px 20px;
}

.faq__content {
  position: relative;
  min-height: 648.47px;
  list-style-type: none;
  /* Used display flex to force gap property to fix hiding alignment */
  display: flex;
  flex-direction: column;
  gap: 0;
}

.faq__overview {
  position: relative;
  z-index: 10;
  transition: 0.5s;
}

.faq__overview--shown {
  opacity: 1;
}

.faq__overview--hidden {
  /* Position absolute to avoid height from affecting height of parent */
  position: absolute;
  opacity: 0;
  transform: translateX(-150%);
}

.faq__grid-container {
  margin: 30px;
}

.faq__button {
  min-width: 300px;
  width: 100%;
  padding: 6px;
  border: none;
  border-radius: 6px;

  background-color: var(--button-background);
  color: var(--button-text);
  font-weight: 500;
}

.faq__category {
  position: relative;
  width: 100%;

  transition: all 0.5s;
}

.faq__category--shown {
  opacity: 1;
  max-height: 5000px;
}

.faq__category--hidden {
  opacity: 0;
  transform: translateX(150%);
  top: -648.47px;
  height: 0;

  transition: all 0.5s;
}

.faq__category--hidden .faq__details {
  /*position: absolute;*/
  max-height: 800px;
}

.faq__category-back {
  width: auto;
  margin: 10px;
  padding: 4px 10px 4px 3px;
  color: var(--text-primary);
  background-color: #d9d9d9ff;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;

  /* Vertically center icon and text in button */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.faq__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* stop back button from taking full width */
}

/* Wrapper div to force faq component to take full width of parent */
.faq__details-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.faq__cost-table {
  width: 100%;
  background-color: #7bb9ec30;
  border-radius: 10px;
}

.faq__cost-table th,
.faq__cost-table td {
  text-align: left;
  padding: 10px 20px;
}

.faq__cost-table th {
  color: var(--text-accent);
  font-size: 24px;
  font-weight: 700;
}

.faq__cost-table td {
  color: var(--text-primary);
  font-size: 18px;
}

.faq__cost-table-note {
  margin: 10px;
  font-size: 12px;
}

.faq__list {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  list-style-type: none;
}
