@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

/* this css is for the Uploaded Orders table */

thead button {
  background-color: white;
  border: white;
  font-family: 'Sora', sans-serif;
}

thead button.ascending::after {
  content: '↓';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '↑';
  display: inline-block;
  margin-left: 1em;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
